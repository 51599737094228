import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import qs from "query-string";
import Calendar from "react-calendar";
import FeatherIcon from "feather-icons-react";

// import {Link} from "react-router-dom";
import RingLoader from "react-spinners/ScaleLoader";
// import Countdown from 'react-countdown-now';

import "react-calendar/dist/Calendar.css";
import "./InformePaciente.css";
import Alert from "./modal/alert";
import { Button, Form } from "react-bootstrap";

class InformePaciente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cedula: "",
      nombre: "",
      apellido: "",
      edad: "",
      sexo: "",
      sexoM: "",
      sexoF: "",
      nombreR: "",
      cedula2: "",
      telefonos: "",
      correo: "",
      casos: "",
      icasos: [],
      fechaCita: "",
      terapeuta: "",
      iterapeuta: [],
      horaCita: "",
      ihoraCita: [],
      tdocumento: "",
      ihorarioDisponible: [],
      lunesI: 1,
      martesI: 2,
      miercolesI: 3,
      juevesI: 4,
      viernesI: 5,
      fechaNaci: moment().format("YYYY-MM-DD"),
      fechad2: moment().format("YYYY-MM-DD"),
      fechainicio2: moment().format("YYYY-MM-DD"),
      fechaSeleccionada: new Date(),
      fechaInicio: new Date(),
      fechaFin: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        31
      ),
      tdiagnostico: "",
      indicacion: "",
      guardarOn: false,
      irutas: [],
      mensajealerta: false,
      consulOn: false,
      loading: false,
      ifechaevo: [],

      fechad: "",
      fechah: "",
      diagnostico: "",
      tratamiento: "",
      dias: "",
      sintoma: "",
      diase: "",
      diash: "",
      examenf: "",
      laboratorio: "",
      ecog: "",
      endoscopia: "",
      ninguno: "",
      anexos: "",
      radiologia: "",
      tac: "",
      otros: "",
      biopsia: "",
      quirurgico: "",
      medico: "",
      procedimiento: "",
      intensidad: "",
      tomar: "",
      arazon: "",
      diast: "",
      indica: "",
      dee: "",
      cantidadde: "",
      tipoinforme: "",
      firma: "",
      nombremadre: "",
      nombrepadre: "",
      apellidomadre: "",
      apellidopadre: "",
      opcedulaM: "CEDULA",
      opcedulaP: "CEDULA",
      identificacionmadre: "",
      identificacionpadre: "",
      correomadre: "",
      correopadre: "",
      responsable: "",
      nombreescuela: "",
      foto: "",
      dia: [],
      mes: [],
      anio: [],
      fechaDia: "",
      fechaMes: "",
      fechaAnio: "",
      celularmadre: "",
      celularpadre: "",
      telecasamadre: "",
      telecasapadre: "",
      personasviven:"",
      
    };
  }

  limpiar = () => {
    this.setState({
      cedula: "",
      nombre: "",
      apellido: "",
      edad: "",
      sexo: "",
      sexoM: "",
      sexoF: "",
      nombreR: "",
      cedula2: "",
      telefonos: "",
      correo: "",
      casos: "",
      fechaCita: "",
      terapeuta: "",
      iterapeuta: [],
      horaCita: "",
      ihoraCita: [],
      tdocumento: "",
      lunesI: 1,
      martesI: 2,
      miercolesI: 3,
      juevesI: 4,
      viernesI: 5,
      fechaNaci: moment().format("YYYY-MM-DD"),
      fechad2: moment().format("YYYY-MM-DD"),
      fechaSeleccionada: new Date(),
      fechaInicio: new Date(),
      fechaFin: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        31
      ),
      tdiagnostico: "",
      indicacion: "",
      mensajealerta: false,
      consulOn: false,
      guardarOn: false,
      ifechaevo: [],
      irutas: [],

      fechad: "",
      fechah: "",
      diagnostico: "",
      tratamiento: "",
      dias: "",
      sintoma: "",
      diase: "",
      diash: "",
      examenf: "",
      laboratorio: "",
      ecog: "",
      endoscopia: "",
      ninguno: "",
      anexos: "",
      radiologia: "",
      tac: "",
      otros: "",
      biopsia: "",
      quirurgico: "",
      medico: "",
      procedimiento: "",
      intensidad: "",
      tomar: "",
      arazon: "",
      diast: "",
      indica: "",
      dee: "",
      cantidadde: "",
      tipoinforme: "",
      firma: "",
      nombremadre: "",
      nombrepadre: "",
      apellidomadre: "",
      apellidomadre: "",
      opcedulaM: "",
      opcedulaP: "",
      identificacionmadre: "",
      identificacionpadre: "",
      correomadre: "",
      correopadre: "",
      responsable: "",
      nombreescuela: "",
      foto: "",
      celularmadre: "",
      celularpadre: "",
      telecasamadre: "",
      telecasapadre: "",
    });
  };

  limpiar3 = () => {
    this.setState({
      casos: "",
      terapeuta: "",
      lunesI: 1,
      martesI: 2,
      miercolesI: 3,
      juevesI: 4,
      viernesI: 5,
      fechaSeleccionada: new Date(),
      ihoraCita: [],
      horaCita: "",
      fechaCita: "",
      guardarOn: false,
    });
  };

  onChange(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name);
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "casos") this.buscarEspecialista(e.target.value);
    if (index === "terapeuta") this.buscarDisponibilidad(e.target.value);
  }
  onChange2(e) {
    //destructurin de los valores enviados por el metodo onchange de cada input
    const { name, value } = e.target;
    let regex = new RegExp("^[0-9-]+$");
    if (regex.test(value)) {
      //console.log(name, value);
      this.setState({
        //al elemento dentro de [] es una key de cada parametro dentro del
        //estado.
        [name]: value,
      });
    }
  }

  onChange3(e) {
    //console.log(e.target.value)
    let index = e.target.getAttribute("id");
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "fechaDia") this.calcularEdad(e.target.value, "", "");
    if (index === "fechaMes") this.calcularEdad("", e.target.value, "");
    if (index === "fechaAnio") this.calcularEdad("", "", e.target.value);
  }

  calcularEdad = (e, f, g) => {
    //console.log('paso '+e)
    var fecha = "";
    fecha = e;
    if (
      this.state.fechaDia.length > 0 ||
      this.state.fechaMes.length > 0 ||
      this.state.fechaAnio.length > 0
    ) {
      // Si la fecha es correcta, calculamos la edad
      // var values = fecha.split("-");
      // var dia = values[2];
      // var mes = values[1];
      // var ano = values[0];

      if (e !== "") {
        var dia = e;
      } else {
        var dia = this.state.fechaDia;
      }
      if (f !== "") {
        var mes = f;
      } else {
        var mes = this.state.fechaMes;
      }
      if (g !== "") {
        var ano = g;
      } else {
        var ano = this.state.fechaAnio;
      }
      // cogemos los valores actuales
      var fecha_hoy = new Date();
      var ahora_ano = fecha_hoy.getYear();
      var ahora_mes = fecha_hoy.getMonth() + 1;
      var ahora_dia = fecha_hoy.getDate();

      // realizamos el calculo
      var edadf = ahora_ano + 1900 - ano;
      if (ahora_mes < mes) {
        edadf--;
      }
      if (mes === ahora_mes && ahora_dia < dia) {
        edadf--;
      }
      if (edadf > 1900) {
        edadf -= 1900;
      }

      this.setState({ edad: edadf });
    } else {
      this.setState({ edad: 0 });
    }
  };

  cargarFechas = () => {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  };

  imprimirinforme = () => {
    let data = new FormData();
    data.append("cedu", this.state.cedula);
    data.append("nombre", this.state.nombre);
    data.append("edad", this.state.edad);
    data.append("nota", this.state.diagnostico);
    data.append("desde", this.state.fechad);
    data.append("firma", this.state.firma);
    data.append("tipoI", "INFORME MEDICO " + this.state.tipoinforme);
    axios({
      method: "post",
      url: "https://fehensa.com/consultorio2/fpdf/reportes/rinfegrePHP.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "reporte.pdf";
      link.click();
    });
  };

  actualizar = (res) => {
    this.setState({
      cedula: res.data.variable1,
      nombre: res.data.variable2,
      edad: res.data.variable3,
      fechad: res.data.variable4,
      fechah: res.data.variable5,
      diagnostico: res.data.variable6,
      tratamiento: res.data.variable7,
      dias: res.data.variable8,
      sintoma: res.data.variable9,
      diase: res.data.variable10,
      diash: res.data.variable11,
      examenf: res.data.variable12,
      laboratorio: res.data.variable13,
      ecog: res.data.variable14,
      endoscopia: res.data.variable15,
      ninguno: res.data.variable16,
      anexos: res.data.variable17,
      radiologia: res.data.variable18,
      tac: res.data.variable19,
      otros: res.data.variable20,
      biopsia: res.data.variable21,
      quirurgico: res.data.variable22,
      medico: res.data.variable23,
      procedimiento: res.data.variable24,
      intensidad: res.data.variable25,
      tomar: res.data.variable26,
      arazon: res.data.variable27,
      diast: res.data.variable28,
      indica: res.data.variable29,
      dee: res.data.variable30,
      cantidadde: res.data.variable31,
      tipoinforme: res.data.variable32,
      firma: res.data.variable33,
    });
  };

  buscarinforme = (e) => {
    //console.log("paso: "+e)
    axios
      .post(
        "https://fehensa.com/consultorio2/informepacientesPHP.php/?codigo=" +
          e +
          "&boton=ConsultarDatos"
      )
      .then((res) => {
        if (res.data) {
          this.actualizar(res);
          console.log(this.state.tipoinforme);
          if (this.state.tipoinforme === "REPOSOMEDICO") {
            this.imprimirreposomedico();
          }
          if (this.state.tipoinforme === "CONSTANCIAMEDICA") {
            this.imprimirconstanciamedica();
          }
          if (this.state.tipoinforme === "INGRESO") {
            this.imprimirinformeingreso();
          }
          if (this.state.tipoinforme === "DE EGRESO") {
            this.imprimirinforme();
          }
          if (this.state.tipoinforme === "EVOLUTIVO") {
            this.imprimirinforme();
          }
          if (this.state.tipoinforme === "QUIRURGICO") {
            this.imprimirinforme();
          }
          if (this.state.tipoinforme === "ESTUPEFACIENTES") {
            this.imprimirestupefacientes();
          }
          if (this.state.tipoinforme === "REFERIDOR") {
            this.imprimirReferidor();
          }
          this.setState({ loading: false });
        }
      });
  };

  buscar = (e) => {
    if (e !== "") {
      axios
        .post(
          "https://fehensa.com/consultorio2/informepacientesPHP.php/?cedula=" +
            e +
            "&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio2/informepacientesPHP.php/?cedula=' + this.state.cedula2 + '&boton=Consultar')
        .then((res) => {
          if (res.data) {
            let xfechaevo = res.data;
            this.setState({
              ifechaevo: xfechaevo,
            });
          }
        });
    }
  };

  buscar2 = () => {
    if (this.state.cedula.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedula);
      data.append("boton", "buscarPlanillaFecha");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable5) {
            this.setState({
              consulOn: false,
              mensajealerta: true,
              tituloM: "Registrar Pacientes.",
              cuerpoM:
                "El Paciente con esta Identificación ya se Encuentra Registrado.",
            });
          } else {
            this.setState({
              consulOn: true,
              // mensajealerta: true,
              // tituloM: "Menu de Citas.",
              // cuerpoM:
              //   "No se encontro la cédula, Debe llenar los datos que se solicitan.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Citas.",
        cuerpoM: "Debe indicar un número de cédula o pasaporte.",
      });
    }
  };

  Mcasos = () => {
    let data = new FormData();
    data.append("boton", "Consultar");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maestroCasosPHP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicasos = res.data;
        this.setState({
          icasos: xicasos,
        });
      });
  };

  buscarDisponibilidad = (e) => {
    let data = new FormData();
    data.append("idcaso", this.state.casos);
    data.append("especialista", e);
    data.append("boton", "ConsultarDisponibilidadDia");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maestroCasosPHP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xlunesI = res.data.variable1;
        let xmartesI = res.data.variable2;
        let xmiercolesI = res.data.variable3;
        let xjuevesI = res.data.variable4;
        let xviernesI = res.data.variable5;
        this.setState({
          lunesI: xlunesI,
          martesI: xmartesI,
          miercolesI: xmiercolesI,
          juevesI: xjuevesI,
          viernesI: xviernesI,
          ihoraCita: [],
          horaCita: "",
          fechaCita: "",
        });
      });
  };

  buscarEspecialista = (e) => {
    let data = new FormData();
    data.append("idcaso", e);
    data.append("cedulaPaciente", this.state.cedula);
    data.append("boton", "ConsultarEspecialistas");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maestroCasosPHP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          let xiterapeuta = res.data.variable2;
          this.setState({
            iterapeuta: xiterapeuta,
            terapeuta: "",
            lunesI: 1,
            martesI: 2,
            miercolesI: 3,
            juevesI: 4,
            viernesI: 5,
            fechaSeleccionada: new Date(),
            ihoraCita: [],
            horaCita: "",
            fechaCita: "",
          });
        } else {
          let xiterapeuta = res.data.variable2;
          this.setState({
            iterapeuta: xiterapeuta,
            terapeuta: "",
            lunesI: 1,
            martesI: 2,
            miercolesI: 3,
            juevesI: 4,
            viernesI: 5,
            fechaSeleccionada: new Date(),
            ihoraCita: [],
            horaCita: "",
            fechaCita: "",
            mensajealerta: true,
            tituloM: "Menu de Citas.",
            cuerpoM:
              "El Paciente ya Tiene Activo el Motivo Seleccionado, por favor Verifique O Elija un Motivo de Consulta Diferente Gracias....",
          });
        }
      });
  };

  guardar() {
    if (
      this.state.cedula.length > 0 &&
      this.state.nombre.length > 0 &&
      this.state.apellido.length > 0 &&
      this.state.fechaDia.length > 0 &&
      this.state.fechaMes.length > 0 &&
      this.state.fechaAnio.length > 0 &&
      this.state.sexo.length > 0 &&
      this.state.nombreescuela.length > 0 &&
      this.state.nombremadre.length > 0 &&
      this.state.apellidomadre.length > 0 &&
      this.state.identificacionmadre.length > 0 &&
      this.state.correomadre.length > 0 &&
      this.state.nombrepadre.length > 0 &&
      this.state.apellidopadre.length > 0 &&
      this.state.identificacionpadre.length > 0 &&
      this.state.correopadre.length > 0 &&
      this.state.responsable.length > 0 &&
      this.state.personasviven.length > 0 &&
      this.state.foto.length > 0
    ) {
      let data = new FormData();

      data.append("nombreAlumno", this.state.nombre);
      data.append("apellidoAlumno", this.state.apellido);
      data.append("cedulaLetra", this.state.tdocumento);
      data.append("cedula", this.state.cedula);
      data.append("edad", this.state.edad);
      data.append("sexo", this.state.sexo);
      data.append("lnacimiento", "");
      data.append(
        "fnacimiento",
        this.state.fechaAnio +
          "-" +
          this.state.fechaMes +
          "-" +
          this.state.fechaDia
      );
      data.append("nacionalidad", "");
      data.append("telefono1", "");
      data.append("domicilio1", "");
      data.append("personaVive", this.state.personasviven);

      data.append("nombreMadre", this.state.nombremadre);
      data.append("apellidoMadre", this.state.apellidomadre);
      data.append("cedulaMadreLetra", this.state.opcedulaM);
      data.append("cedulaMadre", this.state.identificacionmadre);
      data.append("profesionMadre", "");
      data.append("lugarTrabajoMadre", "");
      data.append("telefonoTrabajoMadre", "");
      data.append("celularMadre", this.state.celularmadre);
      data.append("domicilioMadre", "");
      data.append("telefonoCasaMadre", this.state.telecasamadre);
      data.append("correoMadre", this.state.correomadre);
      data.append("nacionalidadMadre", "");

      data.append("nombrePadre", this.state.nombrepadre);
      data.append("apellidoPadre", this.state.apellidopadre);
      data.append("cedulaPadreLetra", this.state.opcedulaP);
      data.append("cedulaPadre", this.state.identificacionpadre);
      data.append("profesionPadre", "");
      data.append("lugarTrabajoPadre", "");
      data.append("telefonoTrabajoPadre", "");
      data.append("celularPadre", this.state.celularpadre);
      data.append("domicilioPadre", "");
      data.append("telefonoCasaPadre", this.state.telecasapadre);
      data.append("correoPadre", this.state.correopadre);
      data.append("nacionalidadPadre", "");

      data.append("fechaInicio", this.state.fechainicio2);
      data.append("curso", "");
      data.append("grado", "");
      data.append("transporte", "");
      data.append("personaEncargada", "");
      data.append("telefonoContacto", "");

      data.append("autorizadoPorMadre", "");
      data.append("autorizadoPorPadre", "");
      data.append("autorizadoPorNana", "");
      data.append("autorizadoPorChofer", "");
      data.append("autorizadoPorOtro", "");
      data.append("nombreNana", "");
      data.append("celularNana", "");
      data.append("correoNana", "");
      data.append("nombreChofer", "");
      data.append("celularChofer", "");
      data.append("correoChofer", "");
      data.append("nombreOtro", "");
      data.append("celularOtro", "");
      data.append("correoOtro", "");

      data.append("personaNoAutorizada", "");
      data.append("nombreNoAutorizado1", "");
      data.append("relacion1", "");
      data.append("nombreNoAutorizado2", "");
      data.append("relacion2", "");
      data.append("nombreNoAutorizado3", "");
      data.append("relacion3", "");

      data.append("nombreEmergencia1", "");
      data.append("relacionEmergencia1", "");
      data.append("telefonoEmergencia1", "");
      data.append("nombreEmergencia2", "");
      data.append("relacionEmergencia2", "");
      data.append("telefonoEmergencia2", "");
      data.append("nombreEmergencia3", "");
      data.append("relacionEmergencia3", "");
      data.append("telefonoEmergencia3", "");

      // estos datos van vacios pertenecen a maternal
      data.append("problemaGestacion", "");
      data.append("antecedentes", "");
      data.append("recibioTratamiento", "");
      data.append("tratamiento", "");
      data.append("duracionEmbarazo", "");
      data.append("tipoParto", "");
      data.append("idiomaMaterno", "");
      data.append("caracteristicaPositiva", "");
      data.append("caracteristicaNegativa", "");
      data.append("primeraExperiencia", "");
      data.append("escuelaAnterior", this.state.nombreescuela);
      data.append("asistioRango", "");
      data.append("atendidoPor", "");
      data.append("horasMadre", "");
      data.append("horasPadre", "");

      // hasta aqui

      data.append("condicionEspecial", "");
      data.append("estudianteHospitalizado", "");
      data.append("ocaciones", "");
      data.append("estudianteOperado", "");
      data.append("causaOperacion", "");
      data.append("estudianteEnfermedad", "");
      data.append("especifiqueEnfermedad", "");
      data.append("estudianteMedicamento", "");
      data.append("especifiqueMedicamento", "");
      data.append("vacunacion", "");
      data.append("peso", "");
      data.append("talla", "");
      data.append("sangre", "");

      data.append("penicilina", "");
      data.append("mariscos", "");
      data.append("yodo", "");
      data.append("merthiolate", "");
      data.append("aspirina", "");
      data.append("citrico", "");
      data.append("laptosa", "");
      data.append("otroa", "");
      data.append("otroalergica", "");
      data.append("ninguna", "");

      data.append("convulsiones", "");
      data.append("asma", "");
      data.append("desmayo", "");
      data.append("diabetes", "");
      data.append("rubeola", "");
      data.append("papera", "");
      data.append("sarampiom", "");
      data.append("varicela", "");
      data.append("tosferina", "");
      data.append("hepatitis", "");
      data.append("amebiasis", "");
      data.append("otroen", "");
      data.append("otroenfermedad", "");
      data.append("ningunae", "");
      data.append("evangelio", "");
      data.append("redessociales", this.state.foto);
      data.append("representanteEstudiante", this.state.responsable);
      if (this.state.responsable === "MADRE") {
        data.append("anombreRecibo", this.state.nombremadre);
        data.append("cedulaRecibo", this.state.identificacionmadre);
      }
      if (this.state.responsable === "PADRE") {
        data.append("anombreRecibo", this.state.nombrepadre);
        data.append("cedulaRecibo", this.state.identificacionpadre);
      }
      if (this.state.responsable === "OTRO") {
        data.append("anombreRecibo", this.state.nombreR);
        data.append("cedulaRecibo", this.state.cedula2);
      }
      data.append("estatus", "P");
      data.append("nivel", "P");
      data.append("boton", "guardar");

      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              guardarOn: true,
              mensajealerta: true,
              tituloM: "REGISTRO DE PACIENTES.",
              cuerpoM: "Los Datos Se Guardaron de Forma Correcta",
            });
            //this.limpiar();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "REGISTRO DE PACIENTES.",
              cuerpoM: "Ocurrio un error..., Verifique por favor",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "REGISTRO DE PACIENTES.",
        cuerpoM: "Existen Datos Obligatorios por llenar, verifique por favor.",
      });
    }
  }

  spineractivar(e) {
    if (this.state.loading === true) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: true,
      });
      if (parseInt(e) > 0) {
        this.buscarinforme(e);
      }
    }
  }

  onClickMonth = (e) => {
    this.setState({
      fechaSeleccionada: e,
    });

    // console.log(e);
    // console.log('mes');
  };
  onClickDay = (e) => {
    // console.log(e);
    // console.log(e.getDay());
    // console.log(e.getDate());
    this.setState({
      fechaSeleccionada: e,
    });
    let data = new FormData();
    data.append("idcaso", this.state.casos);
    data.append("especialista", this.state.terapeuta);
    data.append("dia", e.getDay());
    data.append("fechaY", e.getFullYear());
    data.append("fechaM", e.getMonth());
    data.append("fechaD", e.getDate());
    data.append("boton", "ConsultarDisponibilidadHora");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maestroCasosPHP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xhorasDias = res.data;
        this.setState({
          ihoraCita: xhorasDias,
          fechaCita: "paso",
        });
      });
  };

  Upload = (e) => {
    //console.log(e.target.files)
    this.setState({
      loading: true,
    });
    const form_data = new FormData();

    let name_fvar = "";
    for (let x = 0; x < e.target.files.length; x++) {
      name_fvar = "file_" + String(x + 1);
      form_data.append(name_fvar, e.target.files[x]);
      //console.log(name_fvar)
    }
    form_data.append("boton", "GuardarAdjuntos");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/subirArchivos.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          // alert ('Los ArDocumentos fueron enviados exitosamente');
          let xrutas = res.data.variable3;
          this.setState({
            irutas: xrutas,
          });
        } else {
          if (res.data.variable2.length > 0) {
            this.setState({
              paso: true,
              mensaje: res.data.variable2,
            });
          } else {
            this.setState({
              paso: true,
              mensaje:
                "Es posible que uno o mas archivos no fueron guardados en el servidor",
            });
          }
        }
        //console.log(res);
      });
  };

  ClickUpload = (e) => {
    document.getElementById("upload-file").click();
  };

  eliarchivo = (e, i) => {
    //console.log(e);
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("archivoArriba", e);
    data.append("boton", "eliminarAdjuntos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/subirArchivos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          this.consultarAdjuntos();
        } else {
          this.setState({
            paso: true,
            mensaje: "Hubo un Error los Archivos no Fueron Adjuntados",
          });
        }
        //console.log(res);
      });
  };

  consultarAdjuntos() {
    this.setState({
      loading: true,
      irutas: [],
    });
    let data = new FormData();
    data.append("boton", "consultarAdjuntos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/subirArchivos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          let xrutas = res.data.variable3;
          this.setState({
            irutas: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  }

  componentDidMount() {
    const values = qs.parseUrl(window.location.search);
    const xcedu = values.query["item"];
    this.cargarFechas();
    //this.Mcasos();
    // this.buscar2(xcedu);
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  render() {
    return (
      <div className="conte row">
        <div className="cont-quimioc col-12">
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.loading === true && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="cont-tituloquimioc col-12">
              <span className="tituloquimioc">
                REGISTRO DE DATOS DEL PACIENTE
              </span>
              {/* <Link to="#"><span className="titulo-cerrarquimioc">X</span></Link> */}
            </div>
          </div>

          <div className="row">
            <div className="cont-opcion col-9">
              <label className="opcion">Identificación del Paciente</label>
              <div className="row">
                {/* <div class="form-check">
                  <input
                    type="radio"
                    className="opfirmasi"
                    name="tdocumento"
                    id="extra"
                    value="E"
                    checked={this.state.tdocumento === "E"}
                    onChange={this.onChange.bind(this)}
                  />
                  <label className="titulosn" for="extra">
                    Extranjero
                  </label>
                </div> */}

                <div class="form-check">
                  <input
                    type="radio"
                    className="opfirmasi"
                    name="tdocumento"
                    id="pasapo"
                    value="PASAPORTE"
                    checked={this.state.tdocumento === "PASAPORTE"}
                    onChange={this.onChange.bind(this)}
                  />
                  <label className="titulosn" for="pasapo">
                    Pasaporte
                  </label>
                </div>

                <div class="form-check">
                  <input
                    type="radio"
                    className="opfirmasi"
                    name="tdocumento"
                    id="cedu"
                    value="CEDULA"
                    checked={this.state.tdocumento === "CEDULA"}
                    onChange={this.onChange.bind(this)}
                  />
                  <label className="titulosn" for="cedu">
                    Cédula
                  </label>
                </div>
              </div>
            </div>
            {this.state.tdocumento.length > 0 && (
              <div className="cont-opcion col-12">
                <input
                  disabled={this.state.consulto}
                  className="form-control"
                  type="text"
                  name="cedula"
                  id="cedula"
                  autoComplete="off"
                  //pattern="[0-9] required"
                  //readOnly={this.state.consulOn}
                  value={this.state.cedula}
                  onChange={this.onChange.bind(this)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.buscar2();
                    }
                  }}
                />
                <i
                  className="icono-izquierdac fa fa-search"
                  onClick={this.buscar2.bind(this)}
                >
                  {" "}
                </i>
              </div>
            )}
          </div>

          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Nombres Paciente.</label>
                <input
                  className="form-control"
                  type="text"
                  name="nombre"
                  id="nombre"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.nombre.toUpperCase()}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Apellidos Paciente.</label>
                <input
                  className="form-control"
                  type="text"
                  name="apellido"
                  id="apellido"
                  autoComplete="off"
                  value={this.state.apellido.toUpperCase()}
                  onChange={this.onChange.bind(this)}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-6">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">F/Nacimiento.</label>
                <div className="row">
                  <div className="cont-opcion col-3">
                    <i className="icono-derecho fa fa-asterisk"> </i>
                    <select
                      id="fechaDia"
                      name="fechaDia"
                      value={this.state.fechaDia}
                      onChange={this.onChange3.bind(this)}
                    >
                      <option>-Dia-</option>
                      {this.state.dia.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="cont-opcion col-3">
                    <i className="icono-derecho fa fa-asterisk"> </i>
                    <select
                      id="fechaMes"
                      name="fechaMes"
                      value={this.state.fechaMes}
                      onChange={this.onChange3.bind(this)}
                    >
                      <option>-Mes-</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="cont-opcion col-3">
                    <i className="icono-derecho fa fa-asterisk"> </i>
                    <select
                      id="fechaAnio"
                      name="fechaAnio"
                      value={this.state.fechaAnio}
                      onChange={this.onChange3.bind(this)}
                    >
                      <option>-Año-</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {/* <input
                  className="form-control"
                  type="date"
                  name="fechaNaci"
                  id="fechaNaci"
                  value={this.state.fechaNaci}
                  onChange={this.onChange3.bind(this)}
                /> */}
              </div>
            )}
            {this.state.consulOn === true && (
              <div className="cont-opcion col-4">
                <label className="opcion">Edad.</label>
                <input
                  className="form-control"
                  type="text"
                  name="edad"
                  id="edad"
                  readOnly="readonly"
                  onChange={this.calcularEdad.bind(this)}
                  value={this.state.edad}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Sexo.</label>
              </div>
            )}
            {this.state.consulOn === true && (
              <div class="form-check">
                <input
                  type="radio"
                  className="opfirmasi"
                  name="sexo"
                  id="sexoF"
                  value="FEMENINO"
                  checked={this.state.sexo === "FEMENINO"}
                  onChange={this.onChange.bind(this)}
                />
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="titulosn" for="sexoF">
                  Femenino
                </label>
              </div>
            )}

            {this.state.consulOn === true && (
              <div class="form-check">
                <input
                  type="radio"
                  className="opfirmasi"
                  name="sexo"
                  id="sexoM"
                  value="MASCULINO"
                  checked={this.state.sexo === "MASCULINO"}
                  onChange={this.onChange.bind(this)}
                />
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="titulosn" for="sexoM">
                  Masculino
                </label>
              </div>
            )}
          </div>

          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Diagnostico/Medico Tratante.</label>
                <input
                  className="form-control"
                  type="text"
                  name="personasviven"
                  id="personasviven"
                  autoComplete="off"
                  placeholder="Diagnostico/Medico Tratante:"
                  maxLength="60"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.personasviven.toUpperCase()}
                />
              </div>
            )}
          </div>

          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Grado que Cursa el Paciente.</label>

                <select
                  id="nombreescuela"
                  name="nombreescuela"
                  value={this.state.nombreescuela}
                  onChange={this.onChange.bind(this)}
                >
                  <option>- SELECCIONAR GRADO -</option>
                  <option key="1" value="MATERNAL">
                    MATERNAL
                  </option>
                  <option key="2" value="PREKINDER">
                    PREKINDER
                  </option>
                  <option key="3" value="KINDER">
                    KINDER
                  </option>
                  <option key="4" value="1ER GRADO">
                    1ER GRADO
                  </option>
                  <option key="5" value="2DO GRADO">
                    2DO GRADO
                  </option>
                  <option key="6" value="3ER GRADO">
                    3ER GRADO
                  </option>
                  <option key="7" value="4TO GRADO">
                    4TO GRADO
                  </option>
                  <option key="8" value="5TO GRADO">
                    5TO GRADO
                  </option>
                  <option key="9" value="6TO GRADO">
                    6TO GRADO
                  </option>
                  <option key="10" value="7MO GRADO">
                    7MO GRADO
                  </option>
                  <option key="11" value="8VO GRADO">
                    8VO GRADO
                  </option>
                  <option key="12" value="9NO GRADO">
                    9NO GRADO
                  </option>
                </select>

                {/* <input
                  className="form-control"
                  type="text"
                  name="nombreescuela"
                  id="nombreescuela"
                  autoComplete="off"
                  placeholder="Indique el Grado:"
                  maxLength="50"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.nombreescuela.toUpperCase()}
                /> */}
              </div>
            )}
          </div>

          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Nombre Madre del Paciente.</label>
                <input
                  className="form-control"
                  type="text"
                  name="nombremadre"
                  id="nombremadre"
                  autoComplete="off"
                  placeholder="Nombres:"
                  maxLength="30"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.nombremadre.toUpperCase()}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Apellido Madre del Paciente.</label>
                <input
                  className="form-control"
                  type="text"
                  name="apellidomadre"
                  id="apellidomadre"
                  autoComplete="off"
                  placeholder="Apellidos:"
                  maxLength="30"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.apellidomadre.toUpperCase()}
                />
              </div>
            )}
          </div>

          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">
                  Identificacion Madre del Paciente.
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="identificacionmadre"
                  id="identificacionmadre"
                  autoComplete="off"
                  placeholder="Identificacion:"
                  maxLength="30"
                  required
                  // readOnly={
                  //   this.state.planillaNuevaOn === true
                  //     ? ""
                  //     : "readonly"
                  // }
                  onChange={this.onChange.bind(this)}
                  value={this.state.identificacionmadre}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                {/* <i className="icono-derecho fa fa-asterisk"> </i> */}
                <label className="opcion">D.V. de la Madre.</label>
                <input
                  className="form-control"
                  type="text"
                  name="telecasamadre"
                  id="telecasamadre"
                  autoComplete="off"
                  placeholder="D.V. de la Madre:"
                  maxLength="30"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.telecasamadre.toUpperCase()}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Correo Madre del Paciente.</label>
                <input
                  className="form-control"
                  type="text"
                  name="correomadre"
                  id="correomadre"
                  autoComplete="off"
                  placeholder="Correo:"
                  maxLength="50"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.correomadre.toUpperCase()}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Celular de la Madre.</label>
                <input
                  className="form-control"
                  type="text"
                  name="celularmadre"
                  id="celularmadre"
                  autoComplete="off"
                  placeholder="Celular Madre:"
                  maxLength="30"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.celularmadre.toUpperCase()}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Nombre Padre del Paciente.</label>
                <input
                  className="form-control"
                  type="text"
                  name="nombrepadre"
                  id="nombrepadre"
                  autoComplete="off"
                  placeholder="Nombres:"
                  maxLength="30"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.nombrepadre.toUpperCase()}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Apellido Padre del Paciente.</label>
                <input
                  className="form-control"
                  type="text"
                  name="apellidopadre"
                  id="apellidopadre"
                  autoComplete="off"
                  placeholder="Apellidos:"
                  maxLength="30"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.apellidopadre.toUpperCase()}
                />
              </div>
            )}
          </div>

          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">
                  Identificacion Padre del Paciente.
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="identificacionpadre"
                  id="identificacionpadre"
                  autoComplete="off"
                  placeholder="Identificacion:"
                  maxLength="30"
                  required
                  // readOnly={
                  //   this.state.planillaNuevaOn === true
                  //     ? ""
                  //     : "readonly"
                  // }
                  onChange={this.onChange.bind(this)}
                  value={this.state.identificacionpadre}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                {/* <i className="icono-derecho fa fa-asterisk"> </i> */}
                <label className="opcion">D.V. del Padre.</label>
                <input
                  className="form-control"
                  type="text"
                  name="telecasapadre"
                  id="telecasapadre"
                  autoComplete="off"
                  placeholder="D.V. del Padre:"
                  maxLength="30"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.telecasapadre.toUpperCase()}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Correo Padre del Paciente.</label>
                <input
                  className="form-control"
                  type="text"
                  name="correopadre"
                  id="correopadre"
                  autoComplete="off"
                  placeholder="Correo:"
                  maxLength="50"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.correopadre.toUpperCase()}
                />
              </div>
            )}
          </div>
          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Celular de la Padre.</label>
                <input
                  className="form-control"
                  type="text"
                  name="celularpadre"
                  id="celularpadre"
                  autoComplete="off"
                  placeholder="Celular Padre:"
                  maxLength="30"
                  required
                  onChange={this.onChange.bind(this)}
                  value={this.state.celularpadre.toUpperCase()}
                />
              </div>
            )}
          </div>

          <div className="row">
            {this.state.consulOn === true && (
              <div class="form-check">
                <input
                  type="radio"
                  className="opfirmasi"
                  name="foto"
                  id="fotoS"
                  value="SI"
                  checked={this.state.foto === "SI"}
                  onChange={this.onChange.bind(this)}
                />
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="titulosn" for="fotoS">
                  {" "}
                  SI permito tomar fotos y grabaciones para Redes Sociales.
                </label>
              </div>
            )}

            {this.state.consulOn === true && (
              <div class="form-check">
                <input
                  type="radio"
                  className="opfirmasi"
                  name="foto"
                  id="fotoN"
                  value="NO"
                  checked={this.state.foto === "NO"}
                  onChange={this.onChange.bind(this)}
                />
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="titulosn" for="fotoN">
                  {" "}
                  NO permito tomar fotos y grabaciones para Redes Sociales.
                </label>
              </div>
            )}
          </div>

          <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-9">
                <label className="opcion">
                  A nombre de que persona saldrá la Factura.
                </label>
                <div className="row">
                  {this.state.consulOn === true && (
                    <div class="form-check">
                      <input
                        type="radio"
                        className="opfirmasi"
                        name="responsable"
                        id="respM"
                        value="MADRE"
                        checked={this.state.responsable === "MADRE"}
                        onChange={this.onChange.bind(this)}
                      />
                      <i className="icono-derecho fa fa-asterisk"> </i>
                      <label className="titulosn" for="respM">
                        Madre
                      </label>
                    </div>
                  )}

                  {this.state.consulOn === true && (
                    <div class="form-check">
                      <input
                        type="radio"
                        className="opfirmasi"
                        name="responsable"
                        id="respP"
                        value="PADRE"
                        checked={this.state.responsable === "PADRE"}
                        onChange={this.onChange.bind(this)}
                      />
                      <i className="icono-derecho fa fa-asterisk"> </i>
                      <label className="titulosn" for="respP">
                        Padre
                      </label>
                    </div>
                  )}
                  {this.state.consulOn === true && (
                    <div class="form-check">
                      <input
                        type="radio"
                        className="opfirmasi"
                        name="responsable"
                        id="respO"
                        value="OTRO"
                        checked={this.state.responsable === "OTRO"}
                        onChange={this.onChange.bind(this)}
                      />
                      <i className="icono-derecho fa fa-asterisk"> </i>
                      <label className="titulosn" for="respO">
                        Otro
                      </label>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {this.state.responsable === "OTRO" && (
            <div className="row">
              {this.state.consulOn === true && (
                <div className="cont-opcion col-12">
                  <i className="icono-derecho fa fa-asterisk"> </i>
                  <label className="opcion">RUC/CEDULA</label>
                  <input
                    disabled={this.state.consulto}
                    className="form-control"
                    type="text"
                    name="cedula2"
                    id="cedula2"
                    autoComplete="off"
                    value={this.state.cedula2}
                    onChange={this.onChange.bind(this)}
                  />
                </div>
              )}
            </div>
          )}
          {this.state.responsable === "OTRO" && (
            <div className="row">
              {this.state.consulOn === true && (
                <div className="cont-opcion col-12">
                  <i className="icono-derecho fa fa-asterisk"> </i>
                  <label className="opcion">Nombre Representante.</label>
                  <input
                    className="form-control"
                    type="text"
                    name="nombreR"
                    id="nombreR"
                    autoComplete="off"
                    value={this.state.nombreR.toUpperCase()}
                    onChange={this.onChange.bind(this)}
                  />
                </div>
              )}
            </div>
          )}

          {/* <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Telefonos.</label>
                <input
                  className="form-control"
                  type="text"
                  name="telefonos"
                  id="telefonos"
                  value={this.state.telefonos}
                  onChange={this.onChange.bind(this)}
                />
              </div>
            )}
          </div> */}
          {/* <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Correo.</label>
                <input
                  className="form-control"
                  type="text"
                  name="correo"
                  id="correo"
                  autoComplete="off"
                  value={this.state.correo.toUpperCase()}
                  onChange={this.onChange.bind(this)}
                />
              </div>
            )}
          </div> */}

          {/* <div className="row">
            {this.state.consulOn === true && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                
                <select
                  id="casos"
                  name="casos"
                  value={this.state.casos}
                  onChange={this.onChange.bind(this)}
                >
                  <option>Motivo Consulta.</option>
                  {this.state.icasos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div> */}

          {/* <div className="row">
            {this.state.casos !== "" && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <select
                  id="terapeuta"
                  name="terapeuta"
                  value={this.state.terapeuta}
                  onChange={this.onChange.bind(this)}
                >
                  <option>Terapeuta.</option>
                  {this.state.iterapeuta.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div> */}

          {/* <div className="row">
            {this.state.terapeuta !== "" && (
              <div className="calendar-container col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Fecha Cita.</label>
                <Calendar
                  name="fechaSeleccionada"
                  activeStartDate={this.state.fechaSeleccionada}
                  minDate={this.state.fechaInicio}
                  maxDate={this.state.fechaFin}
                  calendarType="iso8601"
                  prevLabel={false}
                  prev2Label={false}
                  next2Label={false}
                  nextLabel={false}
                  selectRange={false}
                  tileDisabled={({ date }) =>
                    [
                      this.state.lunesI,
                      this.state.martesI,
                      this.state.miercolesI,
                      this.state.juevesI,
                      this.state.viernesI,
                    ].includes(date.getDay())
                  }
                  showNavigation={true}
                  showNeighboringMonth={true}
                  onClickDay={(day) => {
                    this.onClickDay(day);
                  }}
                  //onClickDay={this.onClickDay.bind(this)}
                  //onChange={this.onChange.bind(this)}
                  onClickMonth={this.onClickMonth.bind(this)}
                  value={this.state.fechaSeleccionada}
                />
              </div>
            )}
          </div> */}

          {/* <div className="row">
            {this.state.fechaCita !== "" && (
              <div className="cont-opcion col-12">
                <i className="icono-derecho fa fa-asterisk"> </i>
                <label className="opcion">Hora de la Cita.</label>{" "}
                <select
                  id="horaCita"
                  name="horaCita"
                  value={this.state.horaCita}
                  onChange={this.onChange.bind(this)}
                >
                  <option></option>
                  {this.state.ihoraCita.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div> */}

          {/* {this.state.horaCita !== "" && (
            <div className="row">
              <div className="cont-opcion col-12">
                <label className="opcion">Diagnostico Aplica.</label>
                <div className="row">
                  <div class="form-check">
                    <input
                      type="radio"
                      className="opdiagnostico"
                      name="tdiagnostico"
                      id="diagsi"
                      value="SI"
                      checked={this.state.tdiagnostico === "SI"}
                      onChange={this.onChange.bind(this)}
                    />
                    <label className="titulosn" for="diagsi">
                      SI
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      className="opdiagnostico"
                      name="tdiagnostico"
                      id="diagno"
                      value="NO"
                      checked={this.state.tdiagnostico === "NO"}
                      onChange={this.onChange.bind(this)}
                    />
                    <label className="titulosn" for="diagno">
                      NO
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          {/* {this.state.tdiagnostico === "SI" && (
            <div className="row">
              <div className="cont-opcion col-12">
                <label className="opcion">Indicar.</label>
                <input
                  className="form-control"
                  type="text"
                  name="indicacion"
                  id="indicacion"
                  value={this.state.indicacion.toUpperCase()}
                  onChange={this.onChange.bind(this)}
                />
              </div>
            </div>
          )} */}
          {/* {this.state.tdiagnostico === "SI" && (
            <div className="row">
              <div className="cont-opcion col-12">
                <div
                  className="identificacionr2 col-12"
                  onClick={this.ClickUpload.bind(this)}
                >
                  <p className="subirtexto1">
                    Haz click para adjuntar tus archivos
                  </p>
                  <br />
                  <FeatherIcon icon="upload" size="30px" stroke="#999" />
                  <input
                    onChange={this.Upload.bind(this)}
                    style={{ display: "none" }}
                    id="upload-file"
                    type="file"
                    multiple
                  />
                </div>
              </div>
            </div>
          )} */}
          {/* {this.state.irutas.length > 0 && (
            <div className="row">
              <div className="cont-opcion col-12">
                <div className="titulo-notificacion col-12">
                  <p>Archivos ya Adjuntados.</p>
                </div>
                {this.state.irutas.map((item, i) => {
                  return (
                    <div className="cont-foto1">
                      <span
                        className="cerrar"
                        onClick={this.eliarchivo.bind(this, item.variable5)}
                      >
                        X
                      </span>{" "}
                      {item.variable5}
                    </div>
                  );
                })}
              </div>
            </div>
          )} */}

          {this.state.consulOn === true && (
            <div className="row">
              <div className="botonesgeneral col-12">
                {/* {this.state.tdiagnostico !== "" && */}
                {this.state.guardarOn === false && (
                  <button className="botongr" onClick={this.guardar.bind(this)}>
                    Guardar Paciente
                  </button>
                )}
                {this.state.guardarOn && (
                  <button className="botongr" onClick={this.limpiar.bind(this)}>
                    Nuevo Registro
                  </button>
                )}
                <button className="botongr" onClick={this.limpiar.bind(this)}>
                  Limpiar Información
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default InformePaciente;
